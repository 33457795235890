import {Button} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";

export const HostCourse = () => {
    const navigate = useNavigate();
    const goToHostApplication = () => {
        navigate("/host")
    }

    return (
        <Button type="default" key="console" onClick={goToHostApplication}>
            Host a Course
        </Button>
    );
}