import {Button} from "antd";
import React from "react";
import {HashLink} from "react-router-hash-link";

export const Register = () => {
    return (
        <HashLink to="/#scheduledCourses">
            <Button type="primary" key="console">
                Register Now
            </Button>
        </HashLink>
    );
}