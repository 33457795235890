import {Button, Flex} from "antd";
import {FaMapLocation} from "react-icons/fa6";
import {FaEnvelope, FaPhone} from "react-icons/fa";
import React from "react";

export const ContactButtonGroup = () => {
    return (<Flex vertical={true} gap={8}>
        <Flex gap={'middle'}>
            <Button type={'ghost'} href={"https://www.google.com/maps/place/McKinney,+TX"} target={"_blank"}
                    style={{paddingLeft:0}}
            ><FaMapLocation style={{marginRight:16}}/> McKinney, TX</Button>
        </Flex>
        <Flex gap={'middle'}>
            <Button type={'ghost'} href="mailto:info@phronesistraining.com"
                    style={{paddingLeft:0}}><FaEnvelope style={{marginRight:16}}/> info@phronesistraining.com</Button>
        </Flex>
        <Flex gap={'middle'}>
            <Button type={'ghost'} href="tel:4692155343"
                    style={{paddingLeft:0}}><FaPhone style={{marginRight:16}}/> (469) 215-5343</Button>
        </Flex>
    </Flex>);
}