import {UnauthenticatedNavbar} from "./navbars/unauthenticatedNavbar";
import {Col, Divider, Drawer, Flex, Menu, Row, Space} from "antd";
import {Header} from "antd/es/layout/layout";
import React, {useState} from "react";
import {HostCourse} from "./buttons/HostCourse";
import {Register} from "./buttons/Register";
import {MenuOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useInitialUseEffect} from "../Util/Util";

export const CustomHeader = ({activeTab, solidBackground = true}) => {
    const [scroll, setScroll] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const navigate = useNavigate();
    const onClick = (item) => {
        navigate(item.key)
    };

    useInitialUseEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 56);
        });
    });

    const actionItems = [
        {
            label: 'Host a Course',
            key: '/host',
        },
        {
            label: 'Register Now',
            key: '/#scheduledCourses',
        }];

    return (

        <Header style={{
            position: 'fixed',
            top: 0,
            zIndex: 2,
            width: '100%',
            backgroundColor: solidBackground ? 'black' : scroll ? 'black' : "transparent",
            transition: "all 0.15s ease-in-out",
            boxSizing: 'border-box'
    }}>
            <Row justify={"center"}>
                <Col xs={22} sm={18} >
                    <Flex>
                        <div style={{paddingTop: 12, paddingBottom: 12, marginRight: 12, height: 70}}><a href="/"><img alt='logo' src='/logo.png' /></a></div>

                        <Drawer
                            width={200}
                            open={openMenu}
                            onClose={() => {
                                setOpenMenu(false)
                            }}
                            closable={true}>
                            <Flex align={"end"} vertical={true}>
                                <UnauthenticatedNavbar isInline={true} activeTab={activeTab} />
                                <Divider type="horizontal" />
                                <Menu style={{flex:1, backgroundColor: 'transparent'}} onClick={onClick} mode={"inline"} items={actionItems}/>
                            </Flex>
                        </Drawer>

                        <span className={"headerMenu"} style={{width:'100%', display: 'flex'}}>
                            <UnauthenticatedNavbar activeTab={activeTab} />

                            <Space style={{flex:1, justifyContent: "flex-end"}} size={"small"}>
                                <Flex align={"center"} gap={"small"}>
                                    <Register/><HostCourse/>
                                </Flex>
                            </Space>
                        </span>

                        <span className={"menuIcon"}  >
                            <Space  size={"small"}>
                                <MenuOutlined
                                    style={{color: 'white', fontSize:24}}
                                    onClick={() => {setOpenMenu(true)}}
                                />
                            </Space>
                        </span>
                    </Flex>
                </Col>
            </Row>
    </Header>
    );
}

