import React, {useEffect, useState} from "react";
import {Footer} from "antd/es/layout/layout";
import {Button, Col, Divider, Flex, List, Row} from "antd";
import {ArrowRightOutlined, InstagramOutlined, LinkedinOutlined} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";
import {useNavigate} from "react-router-dom";
import {ContactButtonGroup} from "./buttons/ContactButtonGroup";
import axios from "axios";
import {HashLink} from "react-router-hash-link";

import {API_URL} from "../Util/Constants";
import {useCommonAxiosCalls, useInitialUseEffect} from "../Util/Util";

export const CustomFooter = ({instructorsParam, offeredCoursesParam}) => {
    const navigate = useNavigate();
    const [offeredCourses, setOfferedCourses] = useState([]);
    const [instructors, setInstructors] = useState([]);
    const {GetCourses, GetInstructors} = useCommonAxiosCalls()


    useInitialUseEffect(() => {
        if (instructorsParam) {
            setInstructors(instructorsParam);
        } else {
            GetInstructors(setInstructors);
        }

        if (offeredCoursesParam) {
            setOfferedCourses(offeredCoursesParam);
        } else {
            GetCourses(setOfferedCourses);
        }
    });

    const goToInstructor = (id) => {
        navigate(`/instructor/${id}`);
    }

    const goToCourse = (id) => {
        navigate(`/course/${id}`)
    }

    const goToCourseSchedule = () => {
        navigate("/courseSchedule")
    }

    const goToHost = () => {
        navigate("/host")
    }

    return <Footer
        style={{position: 'relative', bottom: 0}}>
        <Flex vertical={true}>
                <Row gutter={[64,48]} align={"center"}>
                    <Col className={"gap-column"} xs={20} sm={18} md={14} lg={8} xl={8} xxl={8}>
                        <Flex gap={8} style={{flex: 1, marginTop:12}} justify={'flex-start'} align={'flex-end'} vertical={true}>
                            <img style={{height: 'auto', width:300}} alt='logo' src='/logo.png' />
                            <HashLink to="/#scheduledCourses">
                                <Button type={'link'} onClick={() => goToCourseSchedule()}>View Course Schedule <ArrowRightOutlined /></Button>
                            </HashLink>
                            <Button type={'link'} onClick={() => goToHost()}>Host a Course <ArrowRightOutlined /></Button>
                        </Flex>
                    </Col>
                    <Col className={"gap-column"} xs={20} sm={18} md={14} lg={8} xl={8} xxl={8}>
                        <Flex gap={10} style={{flex: 1, marginTop:12}} vertical={true}>
                            <iframe
                                width="auto"
                                height="auto"
                                style={{border:0, flex:1}}
                                loading="async"
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDvUvZJ_MvBiNjf3iODA6xozEdNQYQZ6AE&q=McKinney+TX">
                            </iframe>
                            <ContactButtonGroup />
                        </Flex>
                    </Col>
                    <Col className={"gap-column"}  xs={20} sm={18} md={14} lg={12} xl={8} xxl={8}>
                        <Flex gap={40} style={{flex: 1}}>
                            <Flex vertical={true}>
                                <List
                                    dataSource={instructors}
                                    header={<>
                                        <Title style={{marginTop: 0, wordBreak: 'keep-all'}} level={5}>Instructors</Title>
                                        <div style={{width:60, paddingTop: 0}} className={"header-underline justify-left"}></div>
                                    </>}
                                    split={false}
                                    renderItem={(item) => {
                                        return (
                                            <List.Item style={{padding: '4px 0'}} key={item.id}>
                                                <a className={"text-link"} onClick={() => goToInstructor(item.id)}>{item.first_name + " " + item.last_name}</a>
                                            </List.Item>
                                        )
                                    }}
                                >
                                </List>
                            </Flex>
                            <Flex vertical={true}>
                                <List
                                    dataSource={offeredCourses}
                                    header={<>
                                        <Title style={{marginTop: 0}} level={5}>Offered Courses</Title>
                                        <div style={{width:90, paddingTop: 0}} className={"header-underline justify-left"}></div>
                                    </>}
                                    split={false}
                                    renderItem={(item) => {
                                        return (
                                            <List.Item style={{padding: '4px 0'}} key={item.id}>
                                                <a className={"text-link"} onClick={() => goToCourse(item.id)} type={'text'}>{item.name}</a>
                                            </List.Item>
                                        )
                                    }}
                                >
                                </List>
                            </Flex>
                        </Flex>
                    </Col>
                </Row>


                <Divider></Divider>
                <Flex justify={"space-between"}>
                    <Text>Copyright © 2024 Phronesis Training - All Rights Reserved.</Text>
                    <Flex gap={"small"}>
                        <Button className={"social-icon"} href={"https://www.instagram.com/phronesis_training_group?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="} shape={"circle"} icon={<InstagramOutlined />}></Button>
                        <Button className={"social-icon"} href={"https://www.linkedin.com/in/phronesistraininggroup/"} shape={"circle"} icon={<LinkedinOutlined />}></Button>
                    </Flex>
                </Flex>


            </Flex>

    </Footer>
}