import {Html} from "@react-email/html";
import {Body, Container, Head, Img, Text} from "@react-email/components";
import * as React from "react";

import {
    Section,
} from "@react-email/components";
import {PHRONESIS_URL} from "../Util/Constants";

interface ContactUsEmailProps {
    name?: string;
    email?: string;
    phone?: string;
    contactPreference?: string;
    message?: string;
}

export const ContactUsEmail = ({
                                   name,
                                   email,
                                   phone,
                                   contactPreference,
                                   message
                                       }: ContactUsEmailProps) => {
    return (
        <Html>
            <Head />
            <Body style={main}>
                <Container style={container}>
                    <Img
                        src={`${PHRONESIS_URL}/logo.png`}
                        width="auto"
                        height="45"
                        alt="Phronesis"
                    />
                    <Section>
                        <Text style={text}>Dear Phronesis Team Member,</Text>
                        <Text style={text}>
                            You have received a contact inquiry.
                        </Text>
                        <Text style={text}>
                            Name: {name}
                        </Text>
                        <Text style={text}>
                            Email: {email}
                        </Text>
                        <Text style={text}>
                            Phone: {phone}
                        </Text>
                        <Text style={text}>
                            Contact Method Preference: {contactPreference}
                        </Text>
                        <Text style={text}>
                            Message:
                        </Text>
                        <Text style={text}>
                            {message}
                        </Text>
                    </Section>
                </Container>
            </Body>
        </Html>
    );
};

export default ContactUsEmail;

const main = {
    backgroundColor: "#000000",
    padding: "10px 0",
};

const container = {
    backgroundColor: "#262626",
    border: "1px solid #262626",
    padding: "45px",
};

const text = {
    fontSize: "16px",
    fontFamily:
        "'Open Sans', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
    fontWeight: "300",
    color: "#e5e5e5",
    lineHeight: "26px",
    display: 'block'
};