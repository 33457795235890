import {Button, Card, Col, Flex, Row, Typography} from "antd";
import {Content} from "antd/es/layout/layout";
import React, {useState} from "react";
import Meta from "antd/es/card/Meta";
import {
    ArrowRightOutlined,
    FacebookOutlined,
    InstagramOutlined,
    LinkedinOutlined,
    MailOutlined,
    TwitterOutlined
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {API_URL, PHRONESIS_URL} from "../../Util/Constants";


export const Instructors = ({instructors}) => {
    const { Text } = Typography;
    const [instructorCards, setInstructorCards] = useState();
    const navigate = useNavigate();

    const goToInstructor = (id) => {
        // set the span
        navigate(`/instructor/${id}`);
    }
    const getInstructorCard = (instructor) => {
        const fullName = instructor.first_name + " " + instructor.last_name;

        const getInstaButton = () => {
            if(instructor?.instagram?.trim().length > 0){
                return (<Button style={{textAlign: 'left'}} type={"ghost"} icon={<InstagramOutlined/>}
                        href={addHttpPrefix(instructor.instagram)} target="_blank"></Button>)
            }
        }

        const getLinkedInButton = () => {
            if(instructor?.linkedin?.trim().length > 0){
                return (<Button style={{textAlign: 'left'}} type={"ghost"} icon={<LinkedinOutlined/>}
                                href={addHttpPrefix(instructor.linkedin)} target="_blank"></Button>);
            }
        }

        const getTwitterButton = () => {
            if(instructor?.twitter?.trim().length > 0){
                return (<Button style={{textAlign: 'left'}} type={"ghost"} icon={<TwitterOutlined/>}
                                href={addHttpPrefix(instructor.twitter)} target="_blank"></Button>);
            }
        }

        const getFacebookButton = () => {
            if(instructor?.facebook?.trim().length > 0){
                return (<Button style={{textAlign: 'left'}} type={"ghost"} icon={<FacebookOutlined/>}
                                href={addHttpPrefix(instructor.facebook)} target="_blank"></Button>);
            }
        }

        const getEmailButton = () => {
            return (<Button style={{display:'flex', justifyContent:'center', alignItems:'center'}} shape={"circle"} icon={<MailOutlined style={{fontSize:18}}/>}
                            href={"mailto:" + instructor.email_address} target="_blank"></Button>);

        }

        const addHttpPrefix = (url) => {
            if(url.includes("http")){
                return url;
            } else {
                return "http://".concat(url);
            }
        }

        return (
            <Col key={instructor.id} style={{display: 'flex', alignItems: "stretch"}}
                 xs={12}
                 sm={12}
                 md={12}
                 lg={6}
                 xxl={6}>
                <Card
                    cover={<img alt={fullName} src={API_URL.concat("/" + instructor.image)} />}
                    style={{display: 'flex', flexDirection:'column'}}
                    bodyStyle={{flex:1}}
                    actions={[<span onClick={() => goToInstructor(instructor.id)}>Read More about {instructor.first_name} <ArrowRightOutlined /></span>]}
                >
                    <Flex justify={"center"} align={"flex-start"}>
                        <Meta
                            style={{textAlign: 'center', fontSize:24}}
                            title={<div style={{textAlign: 'center'}}>
                                {fullName}
                            </div>}
                            description={<>
                                <Text style={{textAlign: 'center'}}>{instructor.title}</Text>
                                <Flex justify={"center"} style={{width: '100%', marginTop:16}} >
                                    {getInstaButton()}
                                    {getFacebookButton()}
                                    {getLinkedInButton()}
                                    {getTwitterButton()}
                                    {getEmailButton()}
                                </Flex>
                            </>}
                        />
                    </Flex>

                </Card>
            </Col>
            );
    }

    const getInstructorCards = () => {
        const instructorCards = [];
        instructors.forEach(instructor => {
            instructorCards.push(getInstructorCard(instructor));
        });
        setInstructorCards(instructorCards);

    }


    return (
            <Content>
                <Row gutter={[24, 24]}>
                    {instructorCards ? instructorCards : getInstructorCards()}
                </Row>
            </Content>
    )
}
