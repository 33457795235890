import React from 'react';
import {Menu} from 'antd';
import {useNavigate} from "react-router-dom";

export const UnauthenticatedNavbar = ({activeTab, isInline = false}) => {
    const navigate = useNavigate();

    const items = [
        {
            label:(
                <span>
                    About
                </span>
            ),
            key: '/#about',
            children: [
                {
                    label: 'Instructors',
                    key: '/#instructors',
                },
                {
                    label: 'Testimonials',
                    key: '/#testimonials',
                },
                {
                    label: 'Contact Us',
                    key: '/#contact',
                }
            ]
        },
        {
            label: 'Offered Courses',
            key: '/#offered',
        },
        {
            label: 'Course Schedule',
            key: '/#scheduledCourses',
        },
    ];


    const onClick = (item) => {
        navigate(item.key)
    };
    return <Menu style={{flex:1, backgroundColor:'transparent'}} onClick={onClick} mode={isInline ? "inline" : "horizontal"} items={items} defaultSelectedKeys={activeTab}/>;
};