import React, {useState} from "react";
import {Content} from "antd/es/layout/layout";
import {Button, Card, Checkbox, Col, Flex, Form, Input, Row} from "antd";
import Title from "antd/es/typography/Title";
import {ContactUsEmail} from "../../emails/ContactUsEmail";
import {render} from "@react-email/render";
import {ContactButtonGroup} from "../buttons/ContactButtonGroup";
import {useCommonAxiosCalls} from "../../Util/Util";

export const ContactUs = ({contactEmail}) => {
    const [isPhoneRequired, setIsPhoneRequired] = useState(false);
    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const [form] = Form.useForm();
    const {SendEmail} = useCommonAxiosCalls();

    const setPhone = (event) => {
        setIsPhoneRequired(event.target.checked);
    };

    const setEmail = (event) => {
        setIsEmailRequired(event.target.checked);
    }

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const onFinish = (values) => {
        const emailHtml = render(<ContactUsEmail
            name={values.name}
            email={values.email}
            phone={values.phone}
            contactPreference={values.contactPreference}
            message={values.message}
        ></ContactUsEmail>);

        const options = {
            recipients: [contactEmail],
            subject: "Contact Us",
            html: emailHtml,
        };

        SendEmail(options, {
            loading: 'Sending Message...',
            success: 'Message Sent',
            failure: 'Message failed to send. Please try again later or email an instructor directly.'
        }, () => {
            form.resetFields()
        })
    };

    return (
        <Content>
                    <Card style={{minHeight:600, display:'flex'}} bodyStyle={{display:'flex', alignContent:'stretch', flex:1}}>
                        <Row gutter={[24, 24]} style={{display: 'flex', alignItems: "stretch", flex:1}}>
                            <Col style={{display: 'flex', alignItems: "stretch", width:'100%'}} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                                <Flex vertical={true} style={{flex: 1}} >
                                    <Title level={3} style={{marginTop: 18}}>Contact Us</Title>
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        name="nest-messages"
                                        onFinish={onFinish}
                                        validateMessages={validateMessages}
                                        style={{display: 'flex', flexDirection: 'column', flex:1}}
                                    >
                                        <Form.Item name={'name'} label="Name" rules={[{required: true}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'contactPreference'} label="How should we contact you?"
                                                   rules={[{required: true}]}>
                                            <Checkbox.Group>
                                                <Checkbox value="email" name={"email"} onChange={setEmail}> Email </Checkbox>
                                                <Checkbox value="phone" name={"phone"} onChange={setPhone}> Phone </Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                        <Form.Item name={'email'} hidden={!isEmailRequired} label="Email"
                                                   rules={[{type: 'email', required: isEmailRequired}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'phone'} hidden={!isPhoneRequired} label="Phone Number"
                                                   rules={[{required: isPhoneRequired}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item className={"flex-text-input"} style={{ flex: '1 1 auto'}} name={'message'} label="Message">
                                            <Input.TextArea rows={7} style={{width: '100%', height: '100%', flex:1}} placeholder={"Send us a message..."}/>
                                        </Form.Item>
                                        <Form.Item style={{marginBottom: 0}}>
                                            <Button type="primary" htmlType="submit" >
                                                Send Message
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Flex>
                            </Col>
                            <Col style={{display: 'flex', alignItems: "stretch", width:'100%'}} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                                <Flex vertical={"vertical"} gap={"small"} style={{flex: 1, display: 'flex', alignItems: 'stretch'}}>
                                    <iframe
                                        width="100%"
                                        style={{border:0, flex:"auto"}}
                                        loading="async"
                                        allowFullScreen
                                        referrerPolicy="no-referrer-when-downgrade"
                                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDvUvZJ_MvBiNjf3iODA6xozEdNQYQZ6AE&q=McKinney+TX">
                                    </iframe>

                                    <Flex vertical={true} gap={"small"}>
                                        <ContactButtonGroup />
                                    </Flex>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>

        </Content>
    )
}

