import React, {useState} from "react";
import {Badge, Button, Col, Flex, List, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../../Util/Constants";
import {MdOutlineOpenInNew} from "react-icons/md";
import {LockOutlined, SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import {getMonthAbbrev, useAxios, useInitialUseEffect} from "../../Util/Util";
import dayjs from "dayjs";

export const Schedule = ({scheduledCoursesParam, courseId}) => {
    const navigate = useNavigate();
    const [scheduledCourses, setScheduledCourses] = useState(false);
    const {AxiosGet} = useAxios();

    const getScheduledCourses = () => {
        let url = `scheduledCourses.php`;

        if(courseId){
            url = url.concat(`?id=${courseId}`);
        }

        AxiosGet(url, {}, (response) => {
            const courses = response.data.value;
            setScheduledCourses(courses);
        });
    }

    useInitialUseEffect(()=>{
        if (scheduledCoursesParam) {
            setScheduledCourses(scheduledCoursesParam);
        } else {
            getScheduledCourses();
        }
    })

    const goToCourse = (id) => {
        navigate(`/course/${id}`)
    }

    const getMonths = (firstMonth, secondMonth) => {
        if(firstMonth === secondMonth){
            return getMonthAbbrev(firstMonth);
        } else {
            return getMonthAbbrev(firstMonth) + " - " + getMonthAbbrev(secondMonth);
        }
    }

    return (
        <Content>
            <List
                dataSource={scheduledCourses ? scheduledCourses : getScheduledCourses()}
                header={<List.Item>
                    <Row gutter={{xs: 12, sm: 24}} style={{width: '100%', flex:1}}>
                        <Col xs={4} md={4} lg={4}>
                            Date
                        </Col>
                        <Col xs={8} sm={9} md={9}>
                            Course
                        </Col>
                        <Col xs={7} sm={7} md={7}>
                            Availability
                        </Col>
                        <Col xs={5} sm={4} style={{display: 'flex'}}>

                        </Col>
                    </Row>

                </List.Item>}
                renderItem={(item) => {
                    const schedule = JSON.parse(item.schedule).schedule;
                    const firstDay = schedule[0].day;
                    const dateSeparator = '/';

                    const indexOfSeparator = firstDay.indexOf(dateSeparator);
                    const indexOfLastSeparator = firstDay.lastIndexOf(dateSeparator);

                    let numbers = firstDay.substring(indexOfSeparator + 1, indexOfLastSeparator);
                    let months = getMonthAbbrev(firstDay.substring(0, indexOfSeparator));

                    if(schedule.length > 1){
                        const lastDay = schedule[schedule.length - 1].day;
                        const indexOfLastDaySeparator = lastDay.indexOf(dateSeparator);
                        const indexOfLastDayLastSeparator = lastDay.lastIndexOf(dateSeparator);
                        const lastDayOfMonth = lastDay.substring(indexOfLastDaySeparator + 1, indexOfLastDayLastSeparator);
                        numbers = numbers + " - " + lastDayOfMonth;
                        months = getMonths(firstDay.substring(0, indexOfSeparator), lastDay.substring(0, indexOfLastDaySeparator))
                    }

                    const available = !parseInt(item.full);

                    return (
                        <List.Item key={item.id}>
                            <Row gutter={{xs: 12, sm: 24}} style={{width: '100%', flex:1}}>
                                <Col xs={4} md={4} lg={4}>
                                    <Flex style={{flex:1}} vertical={"vertical"}>
                                        <div>{numbers}</div>
                                        <div>{months}</div>
                                    </Flex>
                                </Col>
                                <Col xs={8} sm={9} md={9} >
                                    <div>
                                        {item.courseDeleted ? <div>{item.courseName}</div> :
                                            <Button style={{display: 'flex', whiteSpace: 'break-spaces', textAlign: 'left', padding:0, width: '100%', height: '100%'}} type={"link"} onClick={() => goToCourse(item.courseId, item.courseName)}><span style={{marginBottom: 'auto'}}>{item.courseName}</span></Button>}
                                        {item.location}
                                    </div>

                                </Col>
                                <Col xs={6} sm={7} md={7}>
                                    {available ? <><Badge status="success" style={{paddingRight: 4}}/> Slots Available</> : <><Badge status="default" style={{paddingRight: 4}}/> Full</>}
                                </Col>
                                <Col xs={6} sm={4} style={{display: "flex", justifyContent: 'flex-end'}}>
                                    {item.open ? <Button disabled={!available} className={'registration-button'} type={"primary"} target="_blank" href={item.registration_link}>
                                        Register <MdOutlineOpenInNew className={"open-in-btn"}></MdOutlineOpenInNew>
                                    </Button> : <div style={{lineHeight: '19px'}}><span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><LockOutlined style={{marginRight:4, height: '19px'}}/><span style={{wordBreak: 'keep-all', whiteSpace: 'break-spaces'}}>Closed Course</span></span></div>}
                                </Col>
                            </Row>
                        </List.Item>
                    )
                }}
            />
        </Content>
    )
}