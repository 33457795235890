import React from "react";
import {Col, Flex, Row, Typography} from "antd";
import {Content} from "antd/es/layout/layout";
import {FadeInSection} from "./FadeInSection";
import Title from "antd/es/typography/Title";

export const HomeContent = ({id, isEven, title, subtitle, child}) => {
    const { Text } = Typography;

    const getTitle = () => {
        if(title){
            return (<FadeInSection children={<div><Title className={"home-content-title"} level={2}>{title}</Title><div className={"header-underline"}></div></div>} />);
        }
    }

    const getSubtitle = () => {
        if(subtitle){
            return (<FadeInSection children={<Text className={"home-content-subtitle"} level={4}>{subtitle}</Text>} />)
        }
    }

    const getContentClass = () => {
        return isEven ? "homeContentEven" : "homeContentOdd";
    }

    return (
        <Content id={id} className={getContentClass()} style={{paddingTop: 32, paddingBottom: 60}}>
            <Row align={"center"}>
                <Col xs={20} sm={20} md={18} lg={18} xl={18} xxl={14} >
                    <Flex vertical={'vertical'} justify={'center'} align={"center"}>
                        {getTitle()}
                        {getSubtitle()}
                    </Flex>
                    <FadeInSection children={child} />
                </Col>
            </Row>

        </Content>
    )
}